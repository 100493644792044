import React, { useState, useRef, useEffect } from "react"
import Amplify, { Auth } from "aws-amplify"

import { ROUTES } from "../constants/routes"
import { cognitoConfig } from "../configs/cognito-config"
import {
  getCurrentUser as getCurrentUserUtil,
  USER_INFO_KEY,
  getLocalStorage,
  checkRedirectionPublicPage,
} from "../utils/utilities"
import type { PubicRouteProps } from "../constants/types"

import { withCheckEnableCountries } from "../components/CheckEnableCountriesHoc/index.js"
import LoadingIndicator from "../components/LoadingIndicator"

const PublicRoute = (props: PubicRouteProps) => {
  const currentUser = getLocalStorage(USER_INFO_KEY) || {}
  const { location, component: Component, ...rest } = props
  const isRedirectionPublicPage = checkRedirectionPublicPage(location.pathname)
  const locationState = location.state
  const [user, setUser] = useState(currentUser) // Fix re-render header when navigate
  const [checkingSession, setCheckingSession] = useState(
    !isRedirectionPublicPage
  )
  const isUnmount = useRef(false)

  useEffect(() => {
    // Only fetch current user for not redirection public page
    // Ticket: https://lesmillsinternational.atlassian.net/browse/LA-688
    if (!isRedirectionPublicPage) {
      getCurrentUser()
    }

    // This is used to avoid memory leak when unmount
    return () => {
      isUnmount.current = true
    }
  }, [])

  const getCurrentUser = async () => {
    Amplify.configure({
      ...cognitoConfig,
      userPoolWebClientId: process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
    })

    Auth.currentAuthenticatedUser()
      .then(() => {
        if (!location.pathname.includes(ROUTES().SIGN_IN)) {
          setCheckingSession(false)

          getCurrentUserUtil(response => {
            if (!isUnmount.current) {
              setUser(response)
            }
          })
        }
      })
      .catch(() => {})
      .finally(() => {
        setCheckingSession(false)
      })
  }

  // Only show loading indicator if it was redirected from /customer/watch
  if (
    checkingSession &&
    locationState &&
    locationState.prevPath === ROUTES().CUSTOMER_WATCH
  ) {
    return <LoadingIndicator isDarkTheme />
  }

  return <Component {...rest} user={user} checkingSession={checkingSession} />
}

export default withCheckEnableCountries(PublicRoute)
